import React, { useState, useEffect } from 'react';



interface ImageCarouselProps {
  images: string[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3500); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex justify-center items-center mt-8">
      {images.map((src, index) => (
        <img
          key={index}
          src={src}
          alt="Phoenix"
          className={`w-full max-w-xs md:max-w-md transition-opacity duration-2000 ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
          style={{ position: index === currentIndex ? 'relative' : 'absolute' }}
        />
      ))}
    </div>
  );
};

export default ImageCarousel;